<template>
<div class="text-placeholder background  h-[18px] rounded-xl px-4"/>
</template>
<script setup lang="ts">
</script>
<style scoped lang="scss">
.background {
  background-color: rgba(var(--v-theme-secondary), 0.12);
}
</style>
